// For IE11
if (typeof window !== "undefined" && window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
if (process.env.NODE_ENV === "development") {
  // For IE https://github.com/vercel/next.js/issues/13231
  require("@webcomponents/shadydom");
}
